<template>
  <div>
    <vx-card>
      <div>
        <vs-table v-model="selectedDomain" :data="domainList">
          <template slot="header">
            <div class="mb-2 flex">
              <vs-input
                placeholder="search"
                @keyup.enter="getDomainList()"
                v-model="search"
              ></vs-input>
              <vs-button @click="getAccountList()" size="large" icon="search">
              </vs-button>
            </div>
          </template>
          <template slot="thead">
            <vs-th> user name</vs-th>
            <vs-th> user ID</vs-th>
            <vs-th> domain </vs-th>
            <vs-th> uuid </vs-th>
            <vs-th> created time </vs-th>
            <vs-th> verify time </vs-th>
            <vs-th> Verifying </vs-th>
            <vs-th> Unverifying </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].user_fullname">
                {{ tr.user_fullname }}
              </vs-td>
              <vs-td :data="data[indextr].user_id">
                {{ tr.user_id }}
              </vs-td>
              <vs-td :data="data[indextr].domain">
                <router-link :to="`/domain/${tr.id}`">
                  {{ data[indextr].domain }}
                </router-link>
              </vs-td>
              <vs-td :data="data[indextr].dns_uuid">
                {{ data[indextr].dns_uuid }}
              </vs-td>
              <vs-td :data="data[indextr].created_at">
                {{ data[indextr].created_at }}</vs-td
              >
              <vs-td :data="data[indextr].verify_at">
                {{ data[indextr].verify_at || "فعال نشده" }}</vs-td
              >
              <vs-td :data="data[indextr].status">
                <vs-button
                  color="success"
                  v-if="data[indextr].status == 0"
                  size="small"
                  type="gradient"
                  @click="verifydomain(data[indextr])"
                  >Verify domain</vs-button
                >
                <vs-button color="#00aa00" v-else size="small" type="line"
                  >Verified Before</vs-button
                >
              </vs-td>
              <vs-td :data="data[indextr].status"
                ><vs-button
                  color="danger"
                  v-if="data[indextr].status == 1"
                  size="small"
                  type="gradient"
                  @click="unverifydomain(data[indextr])"
                  >unverify domain</vs-button
                >
                <vs-button color="#ee0000" v-else size="small" type="line"
                  >Not Verified</vs-button
                >
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>

        <vs-pagination
          class="my-5"
          :total="countPage"
          v-model="pageNumber"
        ></vs-pagination>
        <!-- <p>{{selectedDomain}}</p> -->
        <vue-json-pretty :data="selectedDomain"> </vue-json-pretty>
      </div>
    </vx-card>
  </div>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
export default {
  components: {
    VueJsonPretty
  },
  data() {
    return {
      selectedDomain: "",
      domainList: [],
      search: "",
      countPage: 1,
      pageNumber: 1
    };
  },
  watch: {
    pageNumber() {
      this.getDomainList();
    }
  },
  methods: {
    getDomainList() {
      this.$vs.loading()
      this.$http
        .get(
          `/admin/email/domain/list?page=${this.pageNumber}&search=${this.search}`
        )
        .then(result => {
          this.$vs.loading.close()
          this.countPage = result.data.params.last_page;
          this.domainList = result.data.params.data;
        })
        .catch(err => {
          this.$vs.loading.close()
          console.log(err);
        });
    },
    verifydomain(info) {
      // console.log(info);
      const data = { domain: info.domain, user_id: info.user_id };
      // console.log(data);
      this.$http
        .post("/admin/email/domain/verify", data)
        .then(result => {
          if (result.data.message) {
            this.$vs.notify({
              text: "با موفقیت فعال شد",
              color: "success"
            });
          } else {
            console.log(result);
          }
        })
        .catch(err => {
          // console.log(err);
          this.$vs.notify({ text: err.response.data.message, color: "danger" });
        })
        .finally(() => {
          this.getDomainList();
        });
    },
    unverifydomain(info) {
      // console.log(info);
      const data = { domain: info.domain, user_id: info.user_id };
      // console.log(data);
      this.$http
        .post("/admin/email/domain/unverify", data)
        .then(result => {
          if (result.data.message) {
            this.$vs.notify({
              text: "با موفقیت غیر فعال شد",
              color: "success"
            });
          } else {
            console.log(result);
          }
        })
        .catch(err => {
          // console.log(err);
          this.$vs.notify({ text: err.response.data.message, color: "danger" });
        })
        .finally(() => {
          this.getDomainList();
        });
    }
  },
  created() {
    this.getDomainList();
  }
};
</script>
